import React, { Component } from 'react';
import { base } from '../../base';
import '../../styles/css/Scoreboard.css';
import AnswerItemsComponent from "../small_components/AnswerItemsComponent";

class Scoreboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            currentGame: {},
            tenantVariables: {},
            userPicks: []
        };
    }

    componentDidMount(){
        document.title = "Scoreboard";
        this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
            context: this,
            then(variables){
                this.setState({
                    tenantVariables: variables
                })
            }
        });
        this.currentGameRef = base.bindToState('currentGame', {
            context: this,
            state: "currentGame"
        })
        this.userPicksRef = base.listenTo(`userPicks`, {
            context: this,
            asArray: true,
            then(picks){
                const finalPicks = [];
                let totalCount = 0;
                const objectPicks = {};
                const currentGame = this.state.currentGame || {};
                for(const index in currentGame.racers){
                    const racer = currentGame.racers[index];
                    objectPicks[racer.id] = {};
                    objectPicks[racer.id]['count'] = 0;
                    objectPicks[racer.id]['id'] = racer.id;
                    objectPicks[racer.id]['answerImage'] = racer.gifSelectionImage;
                    objectPicks[racer.id]['itemName'] = racer.racerName;
                }
                for(const index in picks){
                    const pick = picks[index];
                    if(objectPicks[pick.pickid]){
                        objectPicks[pick.pickid]['count'] += 1;
                        totalCount++;
                    }
                }
                for(const countObjectsIndex in objectPicks){
                    finalPicks.push(objectPicks[countObjectsIndex]);
                }
                this.setState({
                    userPicks: finalPicks,
                    totalCount: picks.length,
                    loading: false
                })
            }
        });
    }

    componentWillUnmount() {
        base.removeBinding(this.userPicksRef);
        base.removeBinding(this.tenantVariablesRef);
        base.removeBinding(this.currentGameRef);
    }

    render() {
        const tenantVariables = this.state.tenantVariables || {};
        const finalPicks = this.state.userPicks || [];
        const totalCount = this.state.totalCount || 0;
        let show_percentages = true;
        if(tenantVariables.showScoreboardPercentages === "all"){
            show_percentages = true;
        } else if(tenantVariables.showScoreboardPercentages === "parttime") {
            show_percentages = true;
        }
        const scoreboardAboveAnswersText = tenantVariables.aboveSelectionsText || "Results:"
        const backgroundImage = tenantVariables.leftScoreboardBackground;
        const leftOfScoreboardImage = tenantVariables.leftOfScoreboardImage;
        if(this.state.loading){
            return (
                <div className="loading-screen"/>
            )
        }

        return (
            <div className="grid-wrapper" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
                <div className="grid-container-outer" style={{transform: "translateY(0%)"}}>
                    <div className="grid-left">
                        <div className="creator-awards-logo">
                            <img src={leftOfScoreboardImage} alt="" style={{maxHeight: "calc(100vh - 50px)", maxWidth: "100%", height: "auto", width: "auto"}}/>
                        </div>
                    </div>
                    <div style={{marginRight: "5%"}}>
                        <h1 style={{color:tenantVariables.progressBarQuestionTextColor, marginBottom:"30px", fontSize:tenantVariables.questionLeftText || "44px", fontWeight:700, textShadow: "2px 2px #000", textAlign: "left"}}>{scoreboardAboveAnswersText}</h1>
                        <AnswerItemsComponent totalCount={totalCount} answers={finalPicks} show_percentages={show_percentages} tenantVariables={tenantVariables} fromScoreboard={true} sideBigScreen={true} onClickAnswer={()=>console.log("click")}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Scoreboard;
