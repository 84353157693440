import React, {Component} from 'react';
import {Textfit} from "react-textfit";
import '../../styles/css/ProgressButton.css';

class AnswerItemsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true
        };
    }

    componentDidMount() {
        this.setState({
            loading:false
        })
    }

    render() {
        const itemsToIterate = this.props.answers || [];
        const tenantVariables = this.props.tenantVariables || {};
        const fromScoreboard = this.props.fromScoreboard || false;
        const totalCount = this.props.totalCount || 0;
        return (
            <div className="grid-container user" style={{gridAutoFlow:"row", transform:"translateY(0%)", padding:0, justifyItems: !this.props.sideBigScreen && "center"}}>
                {
                    itemsToIterate.map(function(item,i){
                        let progressWidth = "0";
                        let scoreboardBackgroundColor = tenantVariables.answerBackgroundColor || 'transparent';
                        let progressBarOutlineScoreboardColor = tenantVariables.progressBarOutlineScoreboardColor || "transparent";
                        let progressBarTextColor = tenantVariables.progressBarTextColor || "#000";
                        let progressBarColor = tenantVariables.progressBarColor || "#000";
                        let answerBorderColor = tenantVariables.answerOutlineColor || "transparent";
                        let showRightOrWrong= false;
                        let totalAnswers = item.count;
                        let percent = "0%";
                        let show_percentages = this.props.show_percentages;
                        let container;
                        if(document.getElementsByClassName('grid-container').length > 0){
                            container = document.getElementsByClassName('grid-container')[0];
                        }
                        if(container && totalCount>0){
                            progressWidth = parseInt(container.clientWidth * totalAnswers/totalCount, 10)
                        }
                        if(fromScoreboard){
                            progressBarOutlineScoreboardColor = progressBarOutlineScoreboardColor || "transparent";
                            scoreboardBackgroundColor = tenantVariables.progressBarBackgroundColor || "transparent";
                        } else if(showRightOrWrong) {
                            show_percentages = false;
                        }
                        if(totalCount > 0){
                            percent = (parseInt(totalAnswers/totalCount*100,10)).toString()+"%";
                        }
                        return <div
                                key={i}
                                id={item.id}
                                className="grid-item answerItemClass"
                                style={{background: scoreboardBackgroundColor, color:progressBarTextColor, boxShadow:"unset", outline:"3px solid "+(fromScoreboard?progressBarOutlineScoreboardColor:answerBorderColor), display:"flex", justifyContent:'start', maxWidth: this.props.sideBigScreen?"none":800, minWidth:280, minHeight:fromScoreboard?100:80, lineHeight:1,border:"none",padding:10,borderRadius:0,zIndex:-1, width: "100%",marginBottom:5}}>
                                <div className="progress-button-container" id="progress-button-container" style={{overflow:"visible", display: show_percentages ?"":"none"}}>
                                    <div className="progress-container" style={{margin: -10, width:progressWidth, backgroundColor:progressBarColor, zIndex:0, borderRadius:0}}/>
                                </div>
                                <div style={{display:"grid", gridTemplateColumns:item.answerImage?"repeat(1,1fr 10fr 1fr)":"repeat(1,10fr 1fr)", gridGap:5, alignItems: "center", textAlign:"left"}}>
                                    <img src={item.answerImage} width={fromScoreboard?"100px":"58px"} height={fromScoreboard?"100px":"58px"} alt="" style={{marginLeft:5, display:item.answerImage?"":"none",zIndex:1}}/>
                                    <Textfit mode="multi" min={fromScoreboard?34:12} max={fromScoreboard?60:item.answerImage?32:40} style={{height:fromScoreboard?90:60,alignItems:"center",display:"flex",marginLeft:5,zIndex:1}}>
                                        {item.itemName}
                                    </Textfit>
                                    <span style={{visibility: this.props.show_percentages ? "visible" : "hidden"}}>
                                        <div className="col-md-12" style={{padding:0}}>
                                            <Textfit mode="single" min={fromScoreboard?34:12} max={fromScoreboard?60:item.answerImage?32:40} style={{height:fromScoreboard?90:60,alignItems:"center",display:"flex",padding:5,zIndex:1}}>
                                                {percent}
                                            </Textfit>
                                        </div>
                                    </span>
                                </div>
                            </div>
                    }, this)
                }
            </div>
        );
    }
}

export default AnswerItemsComponent;
