import React, { Component } from 'react'
import '../../styles/css/SideMenu.css'
import '../../styles/css/AdminMain.css'
import {app, base} from '../../base';
import logoWithTextImage from '../../styles/images/sqwad_white_logo_w_text.png';

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
          tenantVariables: {}
        };
        this.logOut = this.logOut.bind(this)
    }

    componentDidMount() {
      this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
      });
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    logOut() {
      app.auth().signOut().then((user, error) => {
        this.setState({ redirect: true })
      });
    }

    render() {
      const variables = this.state.tenantVariables || {};
      const homeLink = "/admin";
      const scratcherGameLink = "/setupgame";
      const ticketsLink = "/setuptickets";
      const racersLink = "/setupracers";
      const teamVariables = "/setupteamvariables";
      const rulesAndRegs = "/setuprulesandregs";
      const ticketEmail = "/setupticketemail";
      const loginVariables = "/setuploginvariables";
      let doNotCollectEmail = variables.doNotCollectEmail;
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        return (
          <div className="admin-sidebar">
            <div className="logo">
              <a href={homeLink}>
                <div className="logo-img"><img src={logoWithTextImage} height="30" alt=""/></div>
              </a>
            </div>
            <div className="nav-container">
              <ul className="nav">
                <li className="side-menu-items">
                  <a href={homeLink}>
                    <span className="fa fa-home pre_side_item"/>
                    <span className="">Dashboard</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                <li className="">
                  <a href={scratcherGameLink}>
                    <span className="fa fa-gamepad pre_side_item"/>
                    <span className="">Games</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                <li className="">
                  <a href={racersLink}>
                    <span className="fa fa-check pre_side_item"/>
                    <span className="">Player Choice</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                <li className="">
                  <a href={ticketsLink}>
                    <span className="fa fa-trophy pre_side_item"/>
                    <span className="">Prizes</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                <li className="">
                  <a href={teamVariables}>
                    <span className="fa-solid fa-palette pre_side_item"/>
                    <span className="">Game Branding</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                <li className="">
                  <a href={rulesAndRegs}>
                    <span className="fa fa-legal pre_side_item"/>
                    <span className="">Rules And Regs</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                {isMlbApp &&
                  <li className="">
                    <a href={ticketEmail}>
                      <span className="fa fa-envelope-open-o pre_side_item"/>
                      <span className="text">MLB APP Message</span>
                      <span className="fa fa-chevron-right after_fa_side"/>
                    </a>
                  </li>
                }
                <li className="" style={{display: doNotCollectEmail || isMlbApp ?"none":""}}>
                  <a href={ticketEmail}>
                    <span className="fa-solid fa-envelope pre_side_item"/>
                    <span className="text">Email Branding</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                <li className="">
                  <a href={loginVariables}>
                    <span className="fa fa-users pre_side_item"/>
                    <span className="text">Fan Login</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
                <div className="bottom-buttons">
                  <li className="" onClick={this.logOut}>
                    <a href={homeLink}>
                      <span className="fa fa-sign-out pre_side_item"/>
                      <span className="text">Logout</span>
                      <span className="fa fa-chevron-right after_fa_side"/>
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </div>

        );
    }
}

export default SideMenu
