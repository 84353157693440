import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import RichTextEditor from 'html-verify-react-rte';

class SetUpTicketEmail extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            emailVariables: '',
            loading:true,
            showTabOneemailComponents: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
        this.emailVariablesRef = base.listenTo(`emailVariables`, {
            context: this,
            state: 'emailVariables',
            then(data){
                let advanced = false;
                if(data && data.sender){
                    advanced = true;
                }
                this.setState({
                    loading: false,
                    advanced: advanced,
                    emailVariables: data
                })
            },
            onFailure(err) {
                this.setState({
                    loading: false
                })
            }
        });
    }

    componentWillUnmount() {
        base.removeBinding(this.emailVariablesRef);
    }

    handleChange(event){
        let target = event.target;
        let value = target.value;
        if(target.type === 'checkbox'){
            value = target.checked;
            this.setState({[target.name]: value})
        } else {
            let emailVariablesCopy = this.state.emailVariables || {};
            emailVariablesCopy[event.target.name] = value
            this.setState({ emailVariables:  emailVariablesCopy});
        }
    }

    handleImageChange(event){
      const name_of_file = event.target.name;
      const target = this[name_of_file];
      const file_to_update =target.files[0];
      this.setState({loading:true})
      const vm = this;
      this.UploadImage.upload_file(file_to_update).then(res => {
          vm.setState({loading:false})
          if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl) {
              let emailVariablesCopy = vm.state.emailVariables;
              emailVariablesCopy[name_of_file] = res.imageUrl
              vm.setState({
                  emailVariables: emailVariablesCopy,
              }, () => {
                  vm[name_of_file].value = ""
              });
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: "Something went wrong, please re-upload your image and try again!",
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    handleRichTextChange = (name, text) => {
        let emailVariablesCopy = this.state.emailVariables;
        emailVariablesCopy[name] = text;
        this.setState({ emailVariables:  emailVariablesCopy});
    }

    handleSubmit(event) {
      event.preventDefault();
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const emailVariables = this.state.emailVariables || {};
        let sender = emailVariables.sender || "";
        let subjectLine = emailVariables.subjectLine || "";
        let helpText = emailVariables.helpText || "";
        let loserImageLink = emailVariables.loserImageLink || "";
        let loserSubjectLine = emailVariables.loserSubjectLine || "";
        let losingHelpText = emailVariables.losingHelpText || "";
        let backgroundImage = emailVariables.emailBackgroundImage || "";
        let losingEmailImage = emailVariables.losingEmailImage || "";
        if(helpText === "<p><br></p>"){
            helpText = "";
        } else {
            helpText = helpText.toString('html')
        }
        if(losingHelpText === "<p><br></p>"){
            losingHelpText = "";
        } else {
            losingHelpText = losingHelpText.toString('html')
        }
        if(isMlbApp && subjectLine.length > 45){
            swal({
                title: 'Uh Oh!',
                text: 'length of Subject cannot exceed 45 characters',
                type: 'error',
                confirmButtonText: 'Ok'
            })
            return;
        }
        this.setState({loading:true})
        const updateRulesObject = {}
        updateRulesObject['helpText'] = helpText;
        updateRulesObject['subjectLine'] = subjectLine;
        updateRulesObject['emailBackgroundImage'] = backgroundImage;
        updateRulesObject['sender'] = sender;
        updateRulesObject['loserSubjectLine'] = loserSubjectLine;
        updateRulesObject['loserImageLink'] = loserImageLink;
        updateRulesObject['losingHelpText'] = losingHelpText;
        updateRulesObject['losingEmailImage'] = losingEmailImage;
      const vm = this;
      base.post("emailVariables/", {
        data: updateRulesObject,
        then(err){
          vm.setState({loading:false})
          if(!err){
            swal({
                title: 'Variables Updated!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
          } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
              })
            // console.log(err)
          }
        }
      })
    }

    tripleToggleControl(element, whichToggle){
        const tabOne = "showTabOne" + whichToggle;
        const tabTwo = "showTabTwo" + whichToggle;
        const tabThree = "showTabThree" + whichToggle;
        const firstElement = document.getElementById('showfirst' + whichToggle);
        const secondElement = document.getElementById('showsecond' + whichToggle);
        const thirdElement = document.getElementById('showthird' + whichToggle);
        if(element === "first"){
            firstElement.classList.add('active');
            secondElement.classList.remove('active');
            if(thirdElement){
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: true,
                [tabTwo]: false,
                [tabThree]: false,
            })
        }else if(element === "second"){
            firstElement.classList.remove('active');
            secondElement.classList.add('active');
            if(thirdElement){
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: true,
                [tabThree]: false,
            })
        } else {
            firstElement.classList.remove('active');
            secondElement.classList.remove('active');
            if(thirdElement){
                thirdElement.classList.add('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: false,
                [tabThree]: true,
            })
        }
    }

    render() {
        let sender = this.state.emailVariables.sender || "";
        let subjectLine = this.state.emailVariables.subjectLine || "";
        let helpText = this.state.emailVariables.helpText || "";
        if(typeof helpText === 'string'){
            helpText = RichTextEditor.createValueFromString(helpText, 'html');
        } else if(!helpText) {
            helpText = RichTextEditor.createEmptyValue()
        }
        let emailBackgroundImage = this.state.emailVariables.emailBackgroundImage || "";
        let loserSubjectLine = this.state.emailVariables.loserSubjectLine || "";
        let losingEmailImage = this.state.emailVariables.losingEmailImage || "";
        let losingHelpText = this.state.emailVariables.losingHelpText || "";
        if(typeof losingHelpText === 'string'){
            losingHelpText = RichTextEditor.createValueFromString(losingHelpText, 'html');
        } else if(!losingHelpText) {
            losingHelpText = RichTextEditor.createEmptyValue()
        }
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
            BLOCK_TYPE_DROPDOWN: [
                {label: 'Normal', style: 'unstyled'},
                {label: 'Heading Large', style: 'header-one'},
                {label: 'Heading Medium', style: 'header-two'},
                {label: 'Heading Small', style: 'header-three'}
            ],
        };
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <p className="admin-header-text" style={{marginBottom:0}}>{isMlbApp ? "Message Branding" : "Email Branding"}</p>
              <p className="admin-subheader-text">This is where you can edit the {isMlbApp ? "message" : "email"} sent to fans</p>
              <div className="container-out">
                <div className="admin-form-box">
                  <form onSubmit={this.handleSubmit} id="create-email-form">
                    <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:20}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                          <li className="nav-item" onClick={()=> this.tripleToggleControl("first", "emailComponents")}>
                              <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showfirstemailComponents">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Winning</span>
                              </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.tripleToggleControl("second", "emailComponents")}>
                              <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showsecondemailComponents">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Losing</span>
                              </a>
                          </li>
                      </ul>
                      <div style={{display: this.state.showTabOneemailComponents ? 'block': 'none'}}>
                          <div className="row col-md-12">
                              <div className="form-group">
                                  <label htmlFor="subjectLine">{isMlbApp ? "Message": "Email"} Subject Line</label>
                                  <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the subject line that your fans will see when they receive their winning {isMlbApp ? "messages": "emails"}</p>
                                  <input id="subjectLine" name="subjectLine" className="form-control" value={subjectLine} onChange={this.handleChange} />
                              </div>
                          </div>
                          {!isMlbApp &&
                              <div className="row">
                                  <div className="form-group col-md-3">
                                      <label htmlFor="helpText">Help Text</label>
                                      <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is where you can tell fans where to contact you with issues.  (Example: Having issues? Email info@help.com for help.)</p>
                                      <RichTextEditor toolbarConfig={toolbarConfig} style={{minHeight:'100px'}} id="helpText" name="helpText" value={helpText} onChange={(text) => this.handleRichTextChange('helpText', text)} />
                                  </div>
                                  <div className="form-group col-md-3" align="center">
                                      <img src={emailBackgroundImage} width="auto" height="100px" alt=""/>
                                      <br/>
                                      <label htmlFor="backgroundImage">Email Header Image<br/>(700px X 400px)</label>
                                      <div className="form-group">
                                          <input style={{display:'none'}}  id="emailBackgroundImage" name="emailBackgroundImage" type="file" ref={input => {this.emailBackgroundImage = input; }} onChange={this.handleImageChange}/>
                                          <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('emailBackgroundImage').click()} />
                                      </div>
                                  </div>
                              </div>
                          }
                      </div>
                      <div style={{display: this.state.showTabTwoemailComponents ? 'block': 'none'}}>
                          <div className="row col-md-12">
                              <div className="form-group">
                                  <label htmlFor="loserSubjectLine">Losing Subject Line</label>
                                  <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the subject line that your fans will see when they receive their losing {isMlbApp ? "messages": "emails"}</p>
                                  <input id="loserSubjectLine" name="loserSubjectLine" className="form-control" value={loserSubjectLine} onChange={this.handleChange} />
                              </div>
                          </div>
                          <div className="row">
                              {!isMlbApp &&
                                  <div className="form-group col-md-3">
                                      <label htmlFor="losingHelpText">Losing Help Text (Optional)</label>
                                      <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>This is where you can tell fans where to contact you with issues. (Example: Having issues? Email info@help.com for help.)</p>
                                      <RichTextEditor toolbarConfig={toolbarConfig} style={{minHeight: '100px'}} id="losingHelpText" name="losingHelpText" value={losingHelpText} onChange={(text) => this.handleRichTextChange('losingHelpText', text)}/>
                                  </div>
                              }
                              <div className="form-group col-md-3" align="center">
                                  <img src={losingEmailImage} width="auto" height="100px" alt=""/>
                                  <br/>
                                  <label htmlFor="losingEmailImage">Losing Image<br/>(337px X 603px)</label>
                                  <div className="form-group">
                                      <input style={{display:'none'}}  id="losingEmailImage" name="losingEmailImage" type="file" ref={input => {this.losingEmailImage = input; }} onChange={this.handleImageChange}/>
                                      <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('losingEmailImage').click()} />
                                  </div>
                              </div>
                          </div>
                      </div>
                      {!isMlbApp &&
                          <>
                              <div className="form-group">
                                <label htmlFor="showAdvancedSettings">Advanced</label>
                                <br/>
                                <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                              </div>
                              {this.state.advanced &&
                                  <div className="form-group">
                                  <label htmlFor="sender">Email Sender</label>
                                  <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the sender your fans will see when they receive their winning emails. BE AWARE: changing the sender could adversely impact delivery rates</p>
                                  <input id="sender" name="sender" type="email" className="form-control" value={sender} onChange={this.handleChange} />
                                  </div>
                              }
                          </>
                      }
                  </form>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpTicketEmail
