import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { appDatabasePrimaryFunctions, base } from '../../base';
import UploadImage from '../utils/UploadImage';
import swal from 'sweetalert2';
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone';
import {validUrl} from '../utils/HelpfulFunction';
import ReactPlayer from 'react-player';

class SetUpRacer extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            racerList: [],
            racerName: "",
            editImage: '',
            racerWinningVideoLink: "",
            selectedRacer: {},
            modal: false,
            editing: false,
            loading:true,
            gifSelectionImage: null,
            gifSelectionImagePreview: {},
            videoLength: null
        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this.racerListRef = base.syncState(`racerList`, {
        context: this,
        state: 'racerList',
        asArray: true
      });
      this.setState({
        loading: false
      })
    }

    componentWillUnmount() {
      base.removeBinding(this.racerListRef);
      URL.revokeObjectURL(this.state.gifSelectionImagePreview.preview);
    }

    handleChange(evt){
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
        // if(evt.target.name === "racerWinningVideoLink"){
        //     this.setState({
        //         [evt.target.name]: value,
        //     })
        // } else {
        //     this.setState({ [evt.target.name]: value });
        // }
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
        swal({
            title: 'Cannot be uploaded',
            text: 'Make sure images are less than 2mbs and videos are less then 20mb and are an acceptable file type',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      this.setState({loading:true})

      this.UploadImage.upload_file(files[0]).then(res => {
        this.setState({loading:false})
            if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
            } else if(res.imageUrl) {
                const nameToUpdate = myArgument + "Preview"
                const fileToUpdate = files[0]
                this.setState({
                    [myArgument]: res.imageUrl,
                    [nameToUpdate]: ({
                        fileToUpdate,
                        preview: URL.createObjectURL(fileToUpdate)
                    })
                });
            } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: 'There was some issue with the image upload, please re-upload and try again',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
            }
        })
    }

    handleVideoChange(evt, whereToSave, whichUploaded){
        const name_of_file = evt.target.name;
        const target = this[name_of_file];
        const file_to_update =target.files[0];
        this.setState({loading:true})
        const vm = this;
        this.UploadImage.upload_file(file_to_update).then(res => {
            vm.setState({loading:false})
            if(res && res.error){
                swal({
                    title: 'Video cannot be uploaded',
                    text: res.error,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            } else if(res && res.imageUrl) {
                vm.setState({
                    [whichUploaded]: true,
                    [whereToSave]: res.imageUrl
                });
            } else {
                swal({
                    title: 'Video cannot be uploaded',
                    text: "Something went wrong, please re-upload your video and try again!",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    createRacer() {
        const racerName = this.state.racerName;
        const racerList = this.state.racerList || [];
        let racerWinningVideoLink = this.state.racerWinningVideoLink;
        const shuffleLink = this.state.shuffleLink;
        const revealLink = this.state.revealLink;
        let videoLength = this.state.videoLength;
        const gifSelectionImage = this.state.gifSelectionImage || this.state.editImage;
        const racerId = appDatabasePrimaryFunctions.ref().push().key;
        const vm = this;
        if(!racerName || !gifSelectionImage){
            swal({
                title: 'Oh uh!',
                text: 'Make sure the name and selection image are added!',
                type: 'warning',
                confirmButtonText: 'Ok'
              })
            return;
        }
        if(racerName.length > 50){
            swal({
                title: 'Oh uh!',
                text: 'Name cannot be longer than 50 characters!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        if(!shuffleLink || !revealLink){
            swal({
                title: 'Oh uh!',
                text: 'Need both a shuffle video and a reveal video',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        } else if(!validUrl(shuffleLink) || !validUrl(revealLink)) {
            swal({
                title: 'Oh uh!',
                text: 'Link is not a valid URL',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }

        for(let racerIndex in racerList){
            const racer = racerList[racerIndex];
            if(racer.racerName === racerName && !this.state.selectedRacer){
                swal({
                    title: 'Oh uh!',
                    text: "This name already exists! Pick another name",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
        }
        this.setState({
            loading:true
        })
        const racer = {};
        racer.racerName = racerName;
        racer.gifSelectionImage = gifSelectionImage;
        // racer.racerWinningVideoLink = racerWinningVideoLink;
        racer.shuffleLink = shuffleLink;
        racer.revealLink = revealLink;
        racer.videoLength = videoLength;
        if(!this.state.editing){
            racer.id = racerId;
            base.post(`racerList/${racerId}`, {
                data: racer,
                then(err){
                    if(!err){
                        vm.setState({
                            loading: false,
                            racerName: "",
                            racerWinningVideoLink: "",
                            shuffleLink: "",
                            revealLink: "",
                            gifSelectionImage: null,
                            videoLength: null,
                            fileUploaded: false,
                            endFileUploaded: false,
                            gifSelectionImagePreview: {},
                            editImage: "",
                            add_video: false,
                            modal:false
                        })
                        swal({
                            title: 'Created Successfully!',
                            type: 'success',
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: true,
                            timer: 10000
                        })
                    } else {
                        vm.setState({loading:false})
                        swal({
                            title: 'There was some error!',
                            text: 'Try again and if the problem persists try logging out and logging back in',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        })
                        // console.log(err)
                    }
                }
            })
        } else if(this.state.editing) {
            const selectedRacerId = this.state.selectedRacer.id || this.state.selectedRacer.key;
            racer.id = selectedRacerId;
            base.post(`racerList/${selectedRacerId}`, {
                data: racer,
                then(err){
                    if(!err){
                        vm.setState({
                            loading: false,
                            racerName: "",
                            racerWinningVideoLink: "",
                            gifSelectionImage: null,
                            videoLength: null,
                            fileUploaded: false,
                            endFileUploaded: false,
                            gifSelectionImagePreview: {},
                            editImage: "",
                            add_video: false,
                            modal:false
                        })
                        vm.setState({loading:false})
                        swal({
                            title: 'Edited Successfully!',
                            type: 'success',
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: true,
                            timer: 10000
                        })
                    } else {
                        vm.setState({loading:false})
                        swal({
                            title: 'There was some error!',
                            text: 'Try again and if the problem persists try logging out and logging back in',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        })
                        // console.log(err)
                    }
                }
            })
        } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try refreshing the page and logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }

    editRacer(event){
        event.preventDefault();
        const array = this.state.racerList;
        const racer = array[event.target.value];
        let addVideo = false;
        let fileUploaded = false;
        let endFileUploaded = false;
        if(!racer){
            swal({
                title: 'Oh uh!',
                text: 'Something went wrong, refresh the page and try again!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }

        if(racer.revealLink){
            fileUploaded = true
        }

        if(racer.shuffleLink){
            endFileUploaded = true
        }

        this.setState({
            modal: !this.state.modal,
            selectedRacer: racer,
            racerName: racer.racerName,
            racerWinningVideoLink: racer.racerWinningVideoLink,
            revealLink: racer.revealLink,
            shuffleLink: racer.shuffleLink,
            add_video: addVideo,
            videoLength: racer.videoLength,
            editingImagePreview: racer.gifSelectionImage,
            editImage: racer.gifSelectionImage,
            fileUploaded: fileUploaded,
            endFileUploaded: endFileUploaded,
            editing: true
        });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        selectedRacer: {},
        racerName: "",
        racerWinningVideoLink: "",
        editingImagePreview: null,
        gifSelectionImage: null,
        gifSelectionImagePreview: {},
        endFileUploaded: false,
        fileUploaded: false,
        revealLink: "",
        shuffleLink: "",
        editImage: '',
        videoLength: null,
        editing: false,
        add_video: false
      });
    }

    deleteRacer(e){
      e.preventDefault();
      const array = this.state.racerList;
      const index = array[e.target.value];
      swal({
            title: 'Delete?',
            text: 'Are you sure you want to do this?  You will no longer be able to use it in any new games',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result)=>{
          if (result.value) {
            base.remove('racerList/' + index.key, function(err) {
              if(err){
                swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            })
          }
        })
    }

    async checkIfRacersInGame(path, item) {
        const racersSnapshot = await appDatabasePrimaryFunctions.ref(path).once('value');

        if (!racersSnapshot.exists()) return false;

        const racersInGame = racersSnapshot.val();
        const racerFoundKey = Object.keys(racersInGame).find(key => racersInGame[key].id === item.id);

        if (!racerFoundKey) return false;

        // Merge existing racer data with updating data
        const mergedData = {
            ...racersInGame[racerFoundKey],
            ...item
        };
        await appDatabasePrimaryFunctions.ref(`${path}/${racerFoundKey}`).update(mergedData);
        return true;
    }

    async updateGamesWithChanges(item) {
        if (!item || !item.id) return;

        this.setState({ loading: true });

        try {
            await this.checkIfRacersInGame('currentGame/racers', item);

            const futureGamesSnapshot = await appDatabasePrimaryFunctions.ref('futureGamesList').once('value');

            if (!futureGamesSnapshot.exists()) {
                this.setState({ loading: false });
                return;
            }

            const futureGames = futureGamesSnapshot.val();
            const updates = {};

            Object.entries(futureGames).forEach(([gameId, futureGame]) => {
                (futureGame.racers || []).forEach((racer, index) => {
                    if (racer.id === item.id) {
                        updates[`futureGamesList/${gameId}/racers/${index}`] = {
                            ...racer,
                            ...item
                        };
                    }
                });
            });

            await appDatabasePrimaryFunctions.ref().update(updates);

            this.setState({ loading: false });

            swal({
                title: 'Successfully Updated!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
            });

        } catch (error) {
            this.setState({ loading: false });
            console.error('Error updating games with changes:', error);
            swal({
                title: 'Update Failed!',
                type: 'error',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
            });
        }
    }

    render() {
        const racerList = this.state.racerList;
        let selectedRacer = this.state.selectedRacer;
        const gifSelectionImagePreview = this.state.gifSelectionImagePreview;
        if(Object.keys(selectedRacer).length === 0){
            selectedRacer = null
        }
        const isDev =  "hidden-ball-dev" === process.env.REACT_APP_FIREBASE_PROJECT_ID;
        const isDemo =  "hidden-ball-demo" === process.env.REACT_APP_FIREBASE_PROJECT_ID;

        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>Game Selection Options</p>
                    <p className="admin-subheader-text">These are what picks will appear during your contest!</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add</button>
                    <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                      {
                        racerList.map(function(item,i){
                          return <div key={i} className="card">
                              <div className="card-body" align="center">
                                <p style={{marginTop:5}}>{item.racerName}</p>
                                <p>
                                  <img width="80%" height="auto" src={item.gifSelectionImage} alt=""/>
                                </p>
                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editRacer.bind(this)} value={i}><span className="fa fa-ellipsis-v"/> Edit</button>
                                <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteRacer.bind(this)} value={i}><span className="fa fa-trash-o"/> Delete</button>
                                  {(isDemo || isDev) &&
                                      <button className="btn btn-secondary delete-button" onClick={() => this.updateGamesWithChanges(item)}>Update Games With Changes</button>
                                  }
                              </div>
                            </div>
                        }, this)
                      }
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <ModalHeader toggle={this.toggle}>Add</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <div className="form-group" >
                        <label htmlFor="racerName">Name</label>
                        <input type="text" id="racerName" name="racerName" className="form-control" value={this.state.racerName} onChange={this.handleChange} placeholder="A unique name to help with identification"/>
                      </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group row mb-3">
                                    <label className="col-md-12 col-form-label" htmlFor="name3"> Shuffle Video</label>
                                    <div className="form-group row" style={{textAlign: "center", alignItems:"center", display: this.state.fileUploaded? 'none' : ''}}>
                                        <div className="col-md-6">
                                            <label htmlFor="shuffleLink">Link</label>
                                            <input type="url" id="shuffleLink" name="shuffleLink" className="form-control" value={this.state.shuffleLink} onChange={this.handleChange} placeholder="https://shufflevideolink.com"/>
                                        </div>
                                        <div className="col-md-1">
                                            <h2><b>OR</b></h2>
                                        </div>
                                        <div className="col-md-2">
                                            <input style={{display:'none'}}  id="raceVideoFile" name="raceVideoFile" type="file" ref={input => {this.raceVideoFile = input; }} onChange={(e)=>this.handleVideoChange(e,"shuffleLink", "fileUploaded")}/>
                                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Video (MP4)" onClick={() => document.getElementById('raceVideoFile').click()} />
                                        </div>
                                    </div>
                                    <div style={{width: 300, margin: "auto", textAlign: "center", display: this.state.shuffleLink? '' : 'none'}}>
                                        Preview <span style={{cursor:"pointer"}} onClick={()=>this.setState({shuffleLink: null, fileUploaded:false}, () => {
                                        document.getElementById('shuffleLink').value="";
                                    })}> ❌</span>
                                        {this.state.add_video && this.state.shuffleLink && !this.state.videoLength &&
                                        <p style={{color:"red"}}>Video error: video couldn't play or is still loading</p>
                                        }
                                        <ReactPlayer
                                            style={{display: this.state.shuffleLink? '' : 'none'}}
                                            url={this.state.shuffleLink}
                                            onDuration={(e)=> this.setState({videoLength: e})}
                                            onError={(e)=>this.setState({videoLength: null})}
                                            muted={true}
                                            playing={true}
                                            controls={true}
                                            preload={"auto"}
                                            width="100%"
                                            height="auto"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group row mb-3">
                                    <label className="col-md-12 col-form-label" htmlFor="name3"> Reveal Video</label>
                                    <div className="form-group row" style={{textAlign: "center", alignItems:"center", display: this.state.endFileUploaded? 'none' : ''}}>
                                        <div className="col-md-6">
                                            <label htmlFor="revealLink">Link</label>
                                            <input type="url" id="revealLink" name="revealLink" className="form-control" value={this.state.revealLink} onChange={this.handleChange} placeholder="https://revealvideolink.com"/>
                                        </div>
                                        <div className="col-md-1">
                                            <h2><b>OR</b></h2>
                                        </div>
                                        <div className="col-md-2">
                                            <input style={{display:'none'}}  id="endVideoFile" name="endVideoFile" type="file" ref={input => {this.endVideoFile = input; }} onChange={(e)=>this.handleVideoChange(e,"revealLink", "endFileUploaded")}/>
                                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Video (MP4)" onClick={() => document.getElementById('endVideoFile').click()} />
                                        </div>
                                    </div>
                                    <div style={{width: 300, margin: "auto", textAlign: "center", display: this.state.revealLink? '' : 'none'}}>
                                        Preview <span style={{cursor:"pointer"}} onClick={()=>this.setState({revealLink: null, endFileUploaded:false}, () => {
                                        document.getElementById('revealLink').value="";
                                    })}> ❌</span>
                                        {this.state.add_end_video && this.state.revealLink && !this.state.endVideoLength &&
                                        <p style={{color:"red"}}>Video error: video couldn't play or is still loading</p>
                                        }
                                        <ReactPlayer
                                            style={{display: this.state.revealLink? '' : 'none'}}
                                            url={this.state.revealLink}
                                            onDuration={(e)=> this.setState({endVideoLength: e})}
                                            onError={(e)=>this.setState({endVideoLength: null})}
                                            muted={true}
                                            playing={true}
                                            controls={true}
                                            preload={"auto"}
                                            width="100%"
                                            height="auto"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                      <div className="form-group" align="center">
                        <label htmlFor="gifSelectionImage" style={{width:'100%'}}>Selection Image (Max width: 500px)</label>
                        <img src={this.state.editImage} width="auto" height="100px" style={{display: this.state.editing ? '' : 'none' }} alt=""/>
                        <Dropzone
                          className="dropzone dz-clickable"
                          accept="image/*"
                          onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'gifSelectionImage')}}
                          multiple={false}
                          maxSize={2200000}>
                          <div className="dz-message needsclick">
                            <span className="fa fa-cloud-upload text-muted" style={{display: gifSelectionImagePreview.preview ? 'none' : ''}}/>
                            <h3 style={{display: gifSelectionImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                            <img
                              style={{display: gifSelectionImagePreview.preview ? '' : 'none'}}
                              src={gifSelectionImagePreview.preview}
                              width="100px"
                              height="auto"
                              alt="Drop your image here"
                            />
                          </div>
                        </Dropzone>
                      </div>
                      <div className="form-group text-center">
                        <button disabled={this.state.add_video && this.state.racerWinningVideoLink && !this.state.videoLength} className="btn btn-primary btn-lg submit-button" id="submitButton" onClick={()=>this.createRacer()}>Submit</button>
                      </div>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
         </div>
        );
    }
}

export default SetUpRacer
