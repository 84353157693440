import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import { base } from '../../base';

class BigScreenVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
          videoState: 0,
          videoLink: "",
          timeToSendEmails: null,
          videoDuration: "0:00"
        };
    }

    onEnded(){
      console.log("Video Ended")
    }

    onDuration(e){
      this.setState({
        videoDuration: e
      })
    }

    onProgress(e){
      var seconds = 0
      var timeToSendEmails = this.state.timeToSendEmails || false;
      var videoDuration = this.state.videoDuration;
      if(typeof timeToSendEmails === "object"){
        if(videoDuration > 5){
          timeToSendEmails = videoDuration - 5
        } else {
          timeToSendEmails = videoDuration
        }
      }
      // console.log(seconds)
      // console.log(timeToSendEmails)
      if(seconds === timeToSendEmails){
        console.log("STATE FIRED")
        this.setState({
          videoState: 2
        })
      }
      if(seconds != parseInt(e.playedSeconds)){
        seconds = parseInt(e.playedSeconds)
      }
    }

    componentDidMount() {
      this.videoStateRef = base.syncState(`currentGame/videoState`, {
        context: this,
        state: 'videoState'
      })
      this.videoLinkRef = base.bindToState(`currentGame/videoLink`, {
        context: this,
        state: 'videoLink'
      })
      this.timeToSendEmailsRef = base.bindToState(`currentGame/timeToSendEmails`, {
        context: this,
        state: 'timeToSendEmails'
      })
    }

    componentWillUnmount() {
      // Make sure to revoke the data uris to avoid memory leaks
      base.removeBinding(this.videoStateRef);
      base.removeBinding(this.videoLinkRef);
      base.removeBinding(this.timeToSendEmailsRef);
    }

    render() {
      var videoState = this.state.videoState
      var videoURL = this.state.videoLink
        return <ReactPlayer url={videoURL} muted={true} playing={videoState === 1} onEnded={this.onEnded} onProgress={ (e) => this.onProgress(e)} onDuration={ (e) => this.onDuration(e)} controls={false} preload={"auto"} width="100%" height="100vh"/>
    }
}

export default BigScreenVideo
